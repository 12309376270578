<template>
  <iframe
    ref="myElement"
    frameborder="1"
    :height="`${height}px`"
    width="100%"
    scrolling="no"
    :src="`${VUE_APP_BASE_AUTH_MODULE}/team/form?id=${teamId}`"
    allow="clipboard-read; clipboard-write"
  />
</template>

<script>
import { toast_success } from '@/libs/toastification'

export default {
  data() {
    return {
      height: 0,
      VUE_APP_BASE_AUTH_MODULE: process.env.VUE_APP_BASE_AUTH_MODULE,
      teamId: this.$route.query.id,
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === this.VUE_APP_BASE_AUTH_MODULE) {
        const {
          type, data, route,
        } = event.data

        if (type === 'SET_DIMENSION') {
          this.height = Math.floor(data.height)
        }

        if (route) {
          this.$router.push(route)
        }
      }
    })
  },
}
</script>
